import dayjs, { ConfigType } from "dayjs";
import "dayjs/locale/nl";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjsBusinessDays from "dayjs-business-days2";

dayjs.locale(window.navigator.language);

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(dayjsBusinessDays);

export default dayjs;
