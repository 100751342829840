import RedirectToLoginIfUnauthorisedGuard from "@/router/guards/RedirectToLoginIfUnauthorisedGuard";
import { RouteConfig } from "vue-router";

const authorisedRoutes: RouteConfig = {
  path: "",
  beforeEnter: RedirectToLoginIfUnauthorisedGuard,
  component: () => import("@/templates/Authorised/Authorised.vue"),
  children: [
    {
      path: "/",
      name: "dashboard",
      component: () => import("@/templates/Authorised/views/Home.vue"),
    },
    {
      path: "user",
      name: "user.index",
      component: () => import("@/modules/user/views/UserTable.vue"),
    },
    {
      path: "user/create",
      name: "user.create",
      component: () => import("@/modules/user/views/UserForm.vue"),
    },
    {
      path: "user/:id/edit",
      name: "user.edit",
      component: () => import("@/modules/user/views/UserForm.vue"),
    },
    {
      path: "sales-order",
      name: "salesOrder.overview",
      component: () => import("@/modules/salesOrder/views/SalesOrderTable.vue"),
    },
    {
      path: "sales-order/concept",
      name: "salesOrder.concept",
      component: () =>
        import("@/modules/salesOrder/views/ConceptSalesOrderTable.vue"),
    },
    {
      path: "sales-order/create",
      name: "salesOrder.create",
      component: () =>
        import("@/modules/salesOrder/views/ConceptSalesOrderForm.vue"),
    },
    {
      path: "client/:clientId/sales-order/:salesOrderId/edit",
      name: "salesOrder.edit",
      component: () =>
        import("@/modules/salesOrder/views/ConceptSalesOrderForm.vue"),
    },
    {
      path: "client/:clientId/sales-order/:salesOrderId",
      name: "salesOrder.show",
      component: () =>
        import("@/modules/salesOrder/views/SalesOrderDetail.vue"),
    },
    {
      path: "product/concept",
      name: "product.concept",
      component: () =>
        import("@/modules/product/views/ConceptProductTable.vue"),
    },
    {
      path: "delivery/concept",
      name: "delivery.concept",
      component: () =>
        import("@/modules/delivery/views/ConceptDeliveryTable.vue"),
    },
    {
      path: "delivery/create",
      name: "delivery.create",
      component: () =>
        import("@/modules/delivery/views/ConceptDeliveryForm.vue"),
    },
    {
      path: "delivery/overview",
      name: "delivery.index",
      component: () =>
        import("@/modules/delivery/views/ReceiptTable.vue"),
    },
    {
      path: "delivery/:deliveryId/edit",
      name: "delivery.edit",
      component: () =>
        import("@/modules/delivery/views/ConceptDeliveryForm.vue"),
    },
    {
      path: "returns/overview",
      name: "returns.index",
      component: () =>
        import("@/modules/returns/views/ReturnsTable.vue"),
    },
    {
      path: "product/create",
      name: "product.create",
      component: () => import("@/modules/product/views/ConceptProductForm.vue"),
    },
    {
      path: "product",
      name: "product.index",
      component: () => import("@/modules/product/views/ProductTable.vue"),
    },
    {
      path: "client/:clientId/product/:productId",
      name: "product.show",
      component: () => import("@/modules/product/views/ProductDetail.vue"),
    },
    {
      path: "client/:clientId/product/concept/:productId/edit",
      name: "product.edit",
      component: () => import("@/modules/product/views/ConceptProductForm.vue"),
    },
    {
      path: "stock",
      name: "stock.index",
      component: () => import("@/modules/stock/views/StockIndexTable.vue"),
    },
    {
      path: "stock/flow",
      name: "stock.flow",
      component: () => import("@/modules/stock/views/StockFlowReportTable.vue"),
    },
    {
      path: "stock/logfin",
      name: "stock.logFin",
      component: () => import("@/modules/logFin/views/LogFinReportTable.vue"),
    },
    {
      path: "stock/batch",
      name: "stock.batchReport",
      component: () =>
        import("@/modules/stock/views/StockBatchReportTable.vue"),
    },
    {
      path: "addressbook",
      name: "addressBook.index",
      component: () =>
        import("@/modules/addressbook/views/AddressBookAddressTable.vue"),
    },
    {
      path: "addressbook/create",
      name: "addressBookAddress.create",
      component: () =>
        import("@/modules/addressbook/views/AddressBookAddressForm.vue"),
    },
    {
      path: "client/:clientId/address-book/:addressBookAddressId/edit",
      name: "addressBookAddress.edit",
      component: () =>
        import("@/modules/addressbook/views/AddressBookAddressForm.vue"),
    },
  ],
};

export default authorisedRoutes;
