import store from "@/application/store/index";
import { NavigationGuard } from "vue-router/types/router";

const redirectToLoginIfUnauthorisedGuard: NavigationGuard = (
  to,
  from,
  next
) => {
  if (!store.getters["authorisation/isLoggedIn"]) {
    next({
      name: "login",
    });
    return;
  }
  next();
};
export default redirectToLoginIfUnauthorisedGuard;
