import { get } from "@/application/api";
import { AxiosResponse } from "axios";
import { UserLevel } from "@/modules/product/enum/UserLevel";

export interface ProfileResponse {
  data: Profile;
}

export interface Profile {
  id: number;
  email: string;
  level: UserLevel;
  client: number;
  clients: Record<string, unknown>;
  name: string;
}

export const profile = (): Promise<AxiosResponse<ProfileResponse>> => {
  return get("profile");
};
