<template>
  <div
    v-if="icon"
    v-html="require(`!html-loader!../assets/images/${icon}.svg`)"
  ></div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "SvgRenderer",
  props: {
    icon: String,
  },
});
</script>
