// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Commit } from "vuex";
import { Profile, profile } from "@/modules/authorisation/api/profile";
import { UserLevel } from "@/modules/product/enum/UserLevel";
import { logout } from "@/modules/authorisation/api/logout";

const initialState = {
  token: "",
  profile: null,
  currentService: null,
};

interface State {
  token: string;
  profile: Partial<Profile> | null;
}

export default {
  namespaced: true,
  state: initialState as State,
  mutations: {
    setAuthorisationToken(state: State, token: string): void {
      state.token = token;
    },
    setProfile(state: State, profile: Record<string, unknown>): void {
      state.profile = profile;
    },
    setEmail(state: State, email: string): void {
      if (state.profile) {
        state.profile.email = email;
        return;
      }
      state.profile = { email };
    },
    resetAuthorisation(state: State): void {
      Object.keys(state).forEach((key) => delete state[key as keyof State]);
      Object.assign(state, initialState);
    },
    setClient(state: State, client: number): void {
      if (state.profile) {
        state.profile.client = client;
        return;
      }
      state.profile = { client };
    },
  },
  actions: {
    async logout({ commit }: { commit: Commit }): Promise<void> {
      commit("resetAuthorisation");
      await logout();
    },
    async fetchProfile({ commit }: { commit: Commit }): Promise<void> {
      const result = await profile();
      const profileResponse = result.data.data;
      commit("setProfile", {
        ...profileResponse,
      });
    },
  },
  getters: {
    isLoggedIn: (state: State): boolean => !!state.token?.length,
    profile: (state: State): State["profile"] | undefined => state?.profile,
    userId: (state: State): number | undefined => state.profile?.id,
    getAuthorisationToken: (state: State): string => state.token,
    isAdmin: (state: State): boolean =>
      state.profile?.level === UserLevel.Administrator,
    isEmployee: (state: State): boolean =>
      state.profile?.level === UserLevel.Hexspoor,
    isClient(state: State): boolean {
      return (
        !!state.profile?.level &&
        ![UserLevel.Administrator, UserLevel.Hexspoor].includes(
          state.profile.level
        )
      );
    },
    userLevel: (state: State): UserLevel | undefined => state.profile?.level,
    client: (state: State): number | undefined => state.profile?.client,
    clients: (state: State): Record<string, unknown> | undefined =>
      state.profile?.clients,
  },
};
