export default (
  uri: string,
  requestBody: Record<string, string | number>
): void => {
  let form = document.createElement("form");

  form.method = "POST";
  form.action = `${process.env.VUE_APP_ROOT_API}/${uri}`;

  form = convertToFormData(form, requestBody);
  submitForm(form);
};

function convertToFormData(
  form: HTMLFormElement,
  requestBody: Record<string, string | number>
) {
  for (const [key, value] of Object.entries(requestBody)) {
    const element = document.createElement("input");
    element.name = key;
    element.value = value.toString();
    form.appendChild(element);
  }
  return form;
}

function submitForm(form: HTMLFormElement) {
  document.body.appendChild(form);
  form.submit();
}
