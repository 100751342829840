
import KFieldGroup from "@/components/crud/fields/KFieldGroup.vue";
import KTextField from "@/components/crud/fields/KTextField.vue";
import { mapGetters } from "vuex";
import { login } from "@/modules/authorisation/api/login";
import { getThrottleErrorMinutes } from "@/application/util/ThrottleErrorResponse";
import postRedirect from "@/application/util/postRedirect";
import Vue, { VueConstructor } from "vue";
import { AxiosError, AxiosResponse } from "axios";
import KBtn from "@/components/KBtn.vue";

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        form: {
          validate: () => void;
        };
      };
    }
  >
).extend({
  name: "Login",
  components: {
    KBtn,
    KFieldGroup,
    KTextField,
  },
  data() {
    return {
      errorMessage: "",
      isLoading: false,
      isValid: false,
      form: {
        email: "",
        password: "",
      },
      isRedirecting: false,
    };
  },
  computed: {
    ...mapGetters({
      findError: "error/find",
    }),
  },
  methods: {
    async handleLogin(): Promise<void> {
      this.isLoading = true;
      try {
        const response = await login(this.form.email, this.form.password);
        this.isRedirecting = true;
        this.redirectToAuthDispense(response.data.data.token);
      } catch (error) {
        const { response } = error as AxiosError;
        const { status } = response as AxiosResponse;

        if (status === 429) {
          this.errorMessage = this.$t("errors.429", {
            minutes: getThrottleErrorMinutes(response as AxiosResponse),
          }) as string;
        }
        this.$refs.form.validate();
      } finally {
        this.isLoading = false;
      }
    },
    redirectToAuthDispense(token: string): void {
      postRedirect("auth/dispense", {
        redirect_uri: "dashboard",
        email: this.form.email,
        token: token,
      });
    },
  },
});
