import global from "./global.json";
import authorisation from "./authorisation.json";
import actions from "./actions.json";
import validation from "./validation.json";
import user from "./user.json";
import crudTable from "./crudTable.json";
import salesOrder from "./salesOrder.json";
import menu from "./menu.json";
import routes from "./routes.json";
import product from "./product.json";
import addressBook from "./addressBook.json";
import stock from "./stock.json";
import delivery from "./delivery.json";
import stockBatchReport from "./stockBatchReport.json";
import receipt from "./receipt.json";
import returns from "./returns.json";


export default {
  global,
  authorisation,
  actions,
  validation,
  user,
  crudTable,
  salesOrder,
  menu,
  routes,
  product,
  addressBook,
  stock,
  stockBatchReport,
  delivery,
  receipt,
  returns
};
