import { post } from "@/application/api";
import { AxiosResponse } from "axios";

export interface LoginResponse {
  data: {
    token: string;
  };
}

export const login = (
  email: string,
  password: string
): Promise<AxiosResponse<LoginResponse>> => {
  return post("auth/login", {
    email,
    password,
  });
};
