
import Vue from "vue";

export default Vue.extend({
  name: "KBtn",
  computed: {
    fieldProps() {
      return {
        color: typeof this.$attrs.text !== "undefined" ? "black" : "primary",
        large: true,
        depressed: true,
        ...this.$attrs,
      };
    },
  },
});
