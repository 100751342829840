
import TheSnackbarQueueHandler from "@/components/TheSnackbarQueueHandler.vue";
import { switchLanguage } from "@/plugins/i18n";
import TheConfirmationDialog from "@/components/TheConfirmationDialog.vue";

export default {
  components: { TheConfirmationDialog, TheSnackbarQueueHandler },
  created(): void {
    if (["nl-NL", "nl"].includes(navigator.language)) {
      switchLanguage("nl-NL");
    }
  },
};
