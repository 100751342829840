import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "../locales/en-GB";
import vuetify from "./vuetify";

Vue.use(VueI18n);

const loadedLocales = ["en-GB"]; // as a fallback

const i18n = new VueI18n({
  locale: "en-GB",
  messages: {
    "en-GB": en,
  },
  fallbackLocale: "en-GB",
  numberFormats: {
    "nl-NL": {
      currency: {
        style: "currency",
        currency: "EUR",
      },
    },
    nl: {
      currency: {
        style: "currency",
        currency: "EUR",
      },
    },
    "en-GB": {
      currency: {
        style: "currency",
        currency: "EUR",
      },
    },
  },
  dateTimeFormats: {
    "nl-NL": {
      long: {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      },
    },
    nl: {
      long: {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      },
    },
    "en-GB": {
      long: {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      },
    },
  },
});

function switchI18nLanguage(locale: string) {
  i18n.locale = locale;
}

async function switchVuetifyLanguage(locale: string) {
  const localeShort = locale.substring(0, 2);

  if (Object.keys(vuetify.framework.lang.locales).indexOf(localeShort) > -1) {
    vuetify.framework.lang.current = localeShort;
    return;
  }

  const vuetifyLanguage = await import(`vuetify/es5/locale/${localeShort}.js`);
  vuetify.framework.lang.locales[localeShort] = vuetifyLanguage.default;
  vuetify.framework.lang.current = localeShort;
}

export const switchLanguage = async (locale: string): Promise<void> => {
  if (i18n.locale === locale) return;
  if (loadedLocales.includes(locale)) {
    switchI18nLanguage(locale);
    await switchVuetifyLanguage(locale);
    return;
  }

  const language = await import(`../locales/${locale}`);
  loadedLocales.push(locale);
  i18n.setLocaleMessage(locale, language.default);

  switchI18nLanguage(locale);
  await switchVuetifyLanguage(locale);
};

export { i18n as default };
